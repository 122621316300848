<template>
    <!-- 愿意免费沟通 -->
    <div>
        <!--    状态0-->
        <div class="appointment-time" v-if="type === 0">
            <h1>{{ $t("vipRecommend.appointmentTime") }}</h1>

            <div class="seven_box">
                <!-- 翻译：预约时间-->
                <p style="margin: 7px 0 26px 0">
                    {{ $t('publish.fiveTime')}}<!--您可以预约5个时间段，建议挑选不同日期。可选择日期范围:系统时间往后7-37天之间-->
                </p>
                <div class="seven_item">
                    <div class="fl">
                        <!-- <el-date-picker v-model="value1" type="date" placeholder="选择日期">
                        </el-date-picker> -->
                        <el-date-picker :picker-options="pickerOptions"
                                        :placeholder="$t('publish.selectDateTime1')/*选择日期时间1*/" class="input-class"
                                        type="datetime"
                                        v-model="time_value.time1" value-format="yyyy-MM-dd HH:mm">
                        </el-date-picker>
                    </div>
                    <img alt="" class="fr" src="../../../assets/images/icon_date@2x.png" width="20px"/>
                </div>
                <div class="seven_item">
                    <div class="fl">
                        <el-date-picker :picker-options="pickerOptions"
                                        :placeholder="$t('publish.selectDateTime2')/*选择日期时间2*/" class="input-class"
                                        type="datetime"
                                        v-model="time_value.time2" value-format="yyyy-MM-dd HH:mm">
                        </el-date-picker>
                    </div>
                    <img alt="" class="fr" src="../../../assets/images/icon_date@2x.png" width="20px"/>
                </div>
                <div class="seven_item">
                    <div class="fl">
                        <el-date-picker :picker-options="pickerOptions"
                                        :placeholder="$t('publish.selectDateTime3')/*选择日期时间3*/" class="input-class"
                                        type="datetime"
                                        v-model="time_value.time3" value-format="yyyy-MM-dd HH:mm">
                        </el-date-picker>
                    </div>
                    <img alt="" class="fr" src="../../../assets/images/icon_date@2x.png" width="20px"/>
                </div>
                <div class="seven_item">
                    <div class="fl">
                        <el-date-picker :picker-options="pickerOptions"
                                        :placeholder="$t('publish.selectDateTime4')/*选择日期时间4*/" class="input-class"
                                        type="datetime"
                                        v-model="time_value.time4" value-format="yyyy-MM-dd HH:mm">
                        </el-date-picker>
                    </div>
                    <img alt="" class="fr" src="../../../assets/images/icon_date@2x.png" width="20px"/>
                </div>
                <div class="seven_item">
                    <div class="fl">
                        <el-date-picker :picker-options="pickerOptions"
                                        :placeholder="$t('publish.selectDateTime5')/*选择日期时间5*/" class="input-class"
                                        type="datetime"
                                        v-model="time_value.time5" value-format="yyyy-MM-dd HH:mm">
                        </el-date-picker>
                    </div>
                    <img alt="" class="fr" src="../../../assets/images/icon_date@2x.png" width="20px"/>
                </div>
            </div>
            <div class="form-wrap">
                <div class="input-text-area">
                    <div class="ita-label">
                        <span>* </span>{{ $t("vipRecommend.mobileNumber") }}
                    </div>
                    <!--<div style="display: inline-block;">-->
                    <!--    <el-select style="width:200px !important;height:50px;" v-model="many.mobileCode" filterable >-->
                    <!--      <el-option v-for="item in areaList.phoneCountriesCodeList" :key="item.id" :label="formatCode(item.code, item.countrysEnglish, item.countrysChinese)" :value="item.code">-->
                    <!--      </el-option>-->
                    <!--    </el-select>-->
                    <!--  </div>-->
                    <!--  <div class="ita-input-fronts"  style="display: inline-block;width:350px;">-->
                    <!--    &lt;!&ndash;<span v-if="many.mobileCode" style="position:absolute;margin-left:-40px;">+{{many.mobileCode}}</span>&ndash;&gt;-->
                    <!--    <div class="v-lines"></div>-->
                    <!--    <input v-model="many.phone" class="iif-input" style="width:200px;" type="text" :placeholder="$t('myProject.pleaseInput')"/>-->
                    <!--  </div>-->

                    <div>
                        <div class="area-code" style="margin-top: 15px;width: 590px">
                            <el-input :placeholder="$t('publish.input')" class="input-with-select" v-model="many.phone">
                                <el-select :placeholder="$t('message.pleaseSelect')/*请选择*/" filterable slot="prepend"
                                           v-model="many.mobileCode">
                                    <el-option :key="item.id"
                                               :label="formatCode(item.code, item.countrysEnglish, item.countrysChinese)"
                                               :value="item.code"
                                               v-for="item in areaList.phoneCountriesCodeList"></el-option>
                                </el-select>
                            </el-input>
                        </div>
                        <!--<span style="color:red;position: absolute;margin-top:10px;" v-show="show9">手机号不能为空</span>-->
                    </div>
                </div>
                <div class="input-text-area">
                    <div class="ita-label"><span>* </span>{{ $t("vipRecommend.email") }}</div>
                    <div class="ita-input-front">
                        <input :placeholder="$t('myProject.pleaseInput')" class="iif-input" type="text"
                               v-model="many.data_mailbox"/>
                    </div>
                </div>
                <div class="input-text-area">
                    <div class="ita-label"><span>* </span>WhatsApp</div>
                    <!--<div style="display: inline-block;">-->
                    <!--    <el-select style="width:200px !important;height:50px;" v-model="many.whatsappCode" filterable >-->
                    <!--      <el-option v-for="item in areaList.phoneCountriesCodeList" :key="item.id" :label="formatCode(item.code, item.countrysEnglish, item.countrysChinese)" :value="item.code">-->
                    <!--      </el-option>-->
                    <!--    </el-select>-->
                    <!--  </div>-->
                    <!--  <div class="ita-input-fronts"  style="display: inline-block;width:350px;">-->
                    <!--    &lt;!&ndash;<span v-if="many.whatsappCode" style="position:absolute;margin-left:-40px;">+{{many.whatsappCode}}</span>&ndash;&gt;-->
                    <!--    <div class="v-lines"></div>-->
                    <!--    <input v-model="many.data_whatsApp" class="iif-input" style="width:200px;" type="text" :placeholder="$t('myProject.pleaseInput')"/>-->
                    <!--  </div>-->
                    <div class="area-code" style="margin-top: 15px;width: 590px">
                        <el-input :placeholder="$t('publish.input')" class="input-with-select"
                                  v-model="many.data_whatsApp">
                            <el-select :placeholder="$t('message.pleaseSelect')/*请选择*/" filterable slot="prepend"
                                       v-model="many.whatsappCode">
                                <el-option :key="item.id"
                                           :label="formatCode(item.code, item.countrysEnglish, item.countrysChinese)"
                                           :value="item.code"
                                           v-for="item in areaList.phoneCountriesCodeList"></el-option>
                            </el-select>
                        </el-input>
                    </div>

                </div>
            </div>
            <div class="leave-message">
                <h1>{{$t('publish.kefuliuyan')}}<!--关于预约给客服的留言--></h1>
                <textarea :placeholder="$t('publish.input')/*请输入*/" cols="30" id="q" name="" rows="10"
                          v-model="many.remarks">
        </textarea>
            </div>
            <div @click="sendMsg">
                <CustomButton style="margin-left: 17px" type="black">{{$t('publish.releaseInterview')}}
                    <!--发布访谈--></CustomButton>
            </div>
        </div>

        <!--    状态1-->
        <div class="appointment-time" v-else-if="type === 1">
            <h1>{{ $t("vipRecommend.appointmentTime") }}</h1>
            <div class="seven_item" style="margin-top: 26px">
                <div class="fl">
                    <!-- <el-date-picker v-model="value1" type="date" placeholder="选择日期">
                      </el-date-picker> -->
                    <el-date-picker :picker-options="pickerOptions"
                                    :placeholder="$t('publish.selectDateTime')/*选择日期时间*/" class="input-class"
                                    type="datetime"
                                    v-model="many_value" value-format="yyyy-MM-dd HH:mm">
                    </el-date-picker>
                </div>
                <img alt="" class="fr" src="../../../assets/images/icon_date@2x.png" width="20px"/>
            </div>
            <div class="form-wrap">
                <div class="input-text-area">
                    <div class="ita-label">
                        <span>* </span>{{ $t("vipRecommend.mobileNumber") }}
                    </div>
                    <!--<div style="display: inline-block;">-->
                    <!--    <el-select style="width:200px !important;height:50px;" v-model="many.mobileCode" filterable >-->
                    <!--      <el-option v-for="item in areaList.phoneCountriesCodeList" :key="item.id" :label="formatCode(item.code, item.countrysEnglish, item.countrysChinese)" :value="item.code">-->
                    <!--      </el-option>-->
                    <!--    </el-select>-->
                    <!--  </div>-->
                    <!--  <div class="ita-input-fronts"  style="display: inline-block;width:350px;">-->
                    <!--    &lt;!&ndash;<span v-if="many.mobileCode" style="position:absolute;margin-left:-40px;">+{{many.mobileCode}}</span>&ndash;&gt;-->
                    <!--    <div class="v-lines"></div>-->
                    <!--    <input v-model="many.phone" class="iif-input" style="width:200px;" type="text" :placeholder="$t('myProject.pleaseInput')"/>-->
                    <!--  </div>-->
                    <div>
                        <div class="area-code" style="margin-top: 15px;width: 590px">
                            <el-input :placeholder="$t('publish.input')" class="input-with-select" v-model="many.phone">
                                <el-select :placeholder="$t('message.pleaseSelect')/*请选择*/" filterable slot="prepend"
                                           v-model="many.mobileCode">
                                    <el-option :key="item.id"
                                               :label="formatCode(item.code, item.countrysEnglish, item.countrysChinese)"
                                               :value="item.code"
                                               v-for="item in areaList.phoneCountriesCodeList"></el-option>
                                </el-select>
                            </el-input>
                        </div>
                        <!--<span style="color:red;position: absolute;margin-top:10px;" v-show="show9">手机号不能为空</span>-->
                    </div>
                </div>
                <div class="input-text-area">
                    <div class="ita-label"><span>* </span>{{ $t("vipRecommend.email") }}</div>
                    <div class="ita-input-front">
                        <input :placeholder="$t('myProject.pleaseInput')" class="iif-input" type="text"
                               v-model="many.data_mailbox"/>
                    </div>
                </div>
                <div class="input-text-area">
                    <div class="ita-label"><span>* </span>WhatsApp</div>
                    <!--<div style="display: inline-block;">-->
                    <!--    <el-select style="width:200px !important;height:50px;" v-model="many.whatsappCode" filterable >-->
                    <!--      <el-option v-for="item in areaList.phoneCountriesCodeList" :key="item.id" :label="formatCode(item.code, item.countrysEnglish, item.countrysChinese)" :value="item.code">-->
                    <!--      </el-option>-->
                    <!--    </el-select>-->
                    <!--  </div>-->
                    <!--  <div class="ita-input-fronts"  style="display: inline-block;width:350px;">-->
                    <!--    &lt;!&ndash;<span v-if="many.whatsappCode" style="position:absolute;margin-left:-40px;">+{{many.whatsappCode}}</span>&ndash;&gt;-->
                    <!--    <div class="v-lines"></div>-->
                    <!--    <input v-model="many.data_whatsApp" class="iif-input" style="width:200px;" type="text" :placeholder="$t('myProject.pleaseInput')"/>-->
                    <!--  </div>-->

                    <div class="area-code" style="margin-top: 15px;width: 590px">
                        <el-input :placeholder="$t('publish.input')" class="input-with-select"
                                  v-model="many.data_whatsApp">
                            <el-select :placeholder="$t('message.pleaseSelect')/*请选择*/" filterable slot="prepend"
                                       v-model="many.whatsappCode">
                                <el-option :key="item.id"
                                           :label="formatCode(item.code, item.countrysEnglish, item.countrysChinese)"
                                           :value="item.code"
                                           v-for="item in areaList.phoneCountriesCodeList"></el-option>
                            </el-select>
                        </el-input>
                    </div>
                </div>
            </div>
            <div class="leave-message">
                <h1>{{$t('publish.kefuliuyan')}}<!--关于预约给客服的留言--></h1>
                <textarea :placeholder="$t('myProject.pleaseInput')" cols="30" id="q" name="" rows="10"
                          v-model="many.remarks">
        </textarea>
            </div>
            <div @click="sendMsg2" class="is-btn-group">
                <CustomButton type="black">{{$t('publish.releaseInterview')}}<!--发布访谈--></CustomButton>
            </div>
        </div>
    </div>
</template>

<script>
    import CustomButton from "@/components/my_project/vip_private_recommendation/CustomButton";
    import {selectCountriesCodeInfo} from '@/api/userApi'

    import {
        initiatePeopleInterview,
        initiatorFeedback,
        vip_initiatorFeedback,
    } from "@/api/projectAbout";
    import {dateLimit} from "@/mixins/date_limit";
    import {Loading} from "element-ui";

    const cityOptions = ["上海", "北京", "广州", "深圳"];
    let loadingInstance = null;

    export default {
        name: "AppointmentTime",
        mixins: [dateLimit],
        data() {
            return {
                star: null,
                txtVal: 0, //文本域计数
                txtContent: "",
                checkedCities: ["上海"],
                cities: cityOptions,
                colors: ["#000000"],
                many_value: "",
                value1: "",
                time_value: {
                    time1: "",
                    time2: "",
                    time3: "",
                    time4: "",
                    time5: "",
                },
                areaList: [],
                many: {
                    data_mailbox: "",
                    mobileCode: "86",
                    phone: "",
                    remarks: "",
                    whatsappCode: "86",
                    data_whatsApp: "",
                },
            };
        },
        props: {
            type: {
                type: Number,
                default: 2,
            },
        },
        components: {
            CustomButton,
        },
        created() {
            //获取地区编号
            this.getArea();
        },
        methods: {
            formatCode(code, en, cn) {
                if (this.$i18n.locale == 'zh') {
                    return "+" + code + "(" + cn + ")";
                } else {
                    return "+" + code + "(" + en + ")";

                }
            },
            async sendMsg2() {
                let formData = {
                    projectId: this.$route.query.id,
                    inviteeSource: parseInt(this.$route.query.invnum) + 1,
                    inviteeIds: this.$route.query.num,
                    invitationTime: this.many_value,
                    mobileNumber: this.many.phone,
                    mailBox: this.many.data_mailbox,
                    whatsApp: this.many.data_whatsApp,
                    mobileCode: this.many.mobileCode,
                    whatsappCode: this.many.whatsappCode,
                    invitationReason: this.many.remarks,
                    inviteType: 1,
                    token: localStorage.getItem("token"),
                };
                loadingInstance = Loading.service({
                    text: '',
                    // spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                let res = await initiatePeopleInterview(formData);
                loadingInstance.close();

                if (res.code == 200) {
                    this.$message({
                        message: res.msg,
                        type: "success",
                    });
                    setTimeout(() => {
                        this.$router.go(-1);
                    }, 800);
                } else {
                    this.$message.error(res.msg)
                }
            },
            async sendMsg() {
                let formData = {
                    feedback: 1,
                    interviewId: this.$route.query.inv,
                    inviteTime1: this.time_value.time1,
                    inviteTime2: this.time_value.time2,
                    inviteTime3: this.time_value.time3,
                    inviteTime4: this.time_value.time4,
                    inviteTime5: this.time_value.time5,
                    mobileNumber: this.many.phone,
                    mailBox: this.many.data_mailbox,
                    whatsApp: this.many.data_whatsApp,
                    inviteMessage: this.many.remarks,
                    whatsappCode: this.many.whatsappCode,
                    mobileCode: this.many.mobileCode,
                    token: localStorage.getItem("token"),
                };
                if (this.$route.query.invnum == 0) {
                    let res = await vip_initiatorFeedback(formData);
                    if (res.code == 200) {
                        this.$message({
                            message: res.msg,
                            type: "success",
                        });
                        setTimeout(() => {
                            this.$router.go(-1);
                        }, 800);
                    } else {
                        this.$message.error(res.msg)
                    }
                } else {
                    let res = await initiatorFeedback(formData);
                    if (res.code == 200) {
                        this.$message({
                            message: res.msg,
                            type: "success",
                        });
                        setTimeout(() => {
                            this.$router.go(-1);
                        }, 800);
                    } else {
                        this.$message.error(res.msg)
                    }
                }
            },
            //获取地区
            getArea() {
                selectCountriesCodeInfo().then((res) => {
                    if (res.code == 200) {
                        this.areaList = res.data;
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            txtChange() {
                this.txtVal = this.txtContent.length;
            },
        },
    };
</script>
<style lang="less">
    .input-class {
        .el-input__inner {
            width: 300px;
        }
    }
</style>

<style scoped>
    .el-date-picker .el-picker-panel__content {
        width: 600px;
    }

    .seven_item {
        width: 587px;
        height: 50px;
        background: #fafafa;
        border-radius: 131px;
        line-height: 50px;
        padding: 0 26px;
        box-sizing: border-box;
        margin-bottom: 20px;
    }

    .seven_item img {
        margin-top: 15px;
    }

    .fl {
        width: 90%;
    }

    .appointment-time {
        margin-top: 54px;
    }

    .appointment-time > h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
    }

    .appointment-time > .tip {
        margin-top: 7px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #949494;
    }

    .time-list {
        margin-top: 23px;
    }

    .time-list > li {
        margin-bottom: 26px;
    }

    .time-list > li:last-child {
        margin-bottom: 0;
    }

    .leave-message {
        margin-top: 55px;
    }

    .leave-message > h1 {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .leave-message textarea {
        width: 695px;
        height: 117px;
        background: #fafafa;
        border-radius: 4px;
        margin-top: 21px;
        resize: none;
        padding: 17px 26px;
        box-sizing: border-box;
    }

    .is-btn-group {
        margin-top: 40px;
        overflow: hidden;
    }

    .radio-label {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    /*111111111111*/
    .appointment-time .time {
        margin-top: 7px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .s-leave-message {
        margin-top: 30px;
    }

    .s-leave-message > h2 {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .s-leave-message > p {
        margin-top: 7px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
    }

    .cancel-yy {
        margin-top: 54px;
    }

    .cancel-yy .btn {
        display: inline-block;
    }

    .cancel-yy > span {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 50px;
        color: #949494;
        margin-left: 33px;
    }

    /*1111111111111*/
    .form-wrap {
        margin-top: 57px;
    }

    .input-text-area {
        margin-bottom: 31px;
    }

    .input-text-area:last-child {
        margin-bottom: 0;
    }

    .ita-label {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 21px;
        color: #000000;
    }

    .ita-label > span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
    }

    .ita-input-front {
        width: 536px;
        height: 50px;
        background: #fafafa;
        border-radius: 131px;
        margin-top: 22px;
        padding: 0 26px;
        line-height: 50px;
    }

    .ita-input-fronts {
        width: 300px;
        height: 50px;
        background: #fafafa;
        border-radius: 131px;
        margin-top: 22px;
        padding: 0 50px;
        line-height: 50px;
    }

    .s-input {
        display: inline-block;
        background-color: transparent;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        width: 60px;
    }

    .v-line {
        display: inline-block;
        vertical-align: middle;
        width: 0;
        height: 22px;
        border: 1px solid #d6d6d6;
        margin-right: 45px;
    }

    .v-lines {
        display: inline-block;
        vertical-align: middle;
        width: 0px;
        height: 22px;
        border: 0.5px solid #d6d6d6;
        margin-right: 45px;
    }

    .ita-input-front > span {
        display: inline-block;

        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .iif-input {
        display: inline-block;

        background-color: transparent;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        width: 300px;
    }

    .people-wrap {
        height: 49px;
        margin-top: 31px;
        line-height: 49px;
    }

    .people-wrap .people-head {
        display: inline-block;
        width: 49px;
        height: 49px;
        background: rgba(0, 0, 0, 0);
        border-radius: 50%;
    }

    .people-wrap .people-name {
        display: inline-block;

        font-size: 15px;
        font-family: BentonSans;
        font-weight: 500;
        color: #000000;
        margin-left: 12px;
    }

    .people-wrap .project-name {
        display: inline-block;

        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-left: 54px;
    }

    .evaluate {
        margin-top: 36px;
    }

    .evaluate-star {
        height: 25px;
        line-height: 25px;
    }

    .evaluate-star > span {
        display: inline-block;
        line-height: 25px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-right: 30px;
    }

    .evaluate-star > .star {
        display: inline-block;
        line-height: 20px;
    }

    .evaluate-content {
        width: 695px;
        height: 121px;
        background: #fafafa;
        border-radius: 4px;
        margin-top: 30px;
        position: relative;
    }

    .evaluate-content textarea {
        width: 99%;
        height: 90px;
        background-color: transparent;
        resize: none;
        padding: 17px 26px;

        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
    }

    .numberV {
        position: absolute;
        right: 24px;
        bottom: 13px;

        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .evaluate-result {
        margin-top: 57px;
        padding: 27px 0 57px 0;
        border-top: 1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
    }

    .evaluate-result > h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
    }

    .evaluate-result-content {
        overflow: hidden;
        margin-top: 30px;
    }

    .evaluate-result-content > img {
        float: left;
        width: 67px;
        height: 67px;
        background: rgba(0, 0, 0, 0);
        border-radius: 50%;
    }

    .erc-r {
        margin-left: 13px;
        padding-top: 8px;
    }

    .erc-r-t {
        width: 988px;
        height: 60px;
    }

    .erc-r-t-l {
        float: left;
        height: 60px;
    }

    .erc-r-t-l-name {
        font-size: 17px;
        font-family: BentonSans;
        font-weight: 500;
        line-height: 20px;
        color: #000000;
        margin-bottom: 14px;
    }

    .erc-r-t-r {
        float: right;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        line-height: 60px;
    }

    .erc-r-b {
        margin-top: 20px;
        width: 988px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 23px;
        color: #707070;
    }

    /*radio样式*/
    input[type="radio"] {
        /*同样，首先去除浏览器默认样式*/
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        /*编辑我们自己的样式*/
        position: relative;
        width: 16px;
        height: 16px;
        bottom: 3px;
        margin-right: 14px;

        background: transparent;
        border: 1px solid #8a8a8a;
        /*border-radius: 4px;*/
        outline: none;
        cursor: pointer;
    }

    input[type="radio"] {
        /*同样，首先去除浏览器默认样式*/
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        /*编辑我们自己的样式*/
        position: relative;
        background: url("../../../assets/images/radio_n@2x.png") no-repeat;
        background-size: 100% 100%;
        border: 0;
    }

    input[type="radio"]:after {
        content: "";
        position: absolute;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        border: 0;
        text-align: center;
        line-height: 18px;
    }

    input[type="radio"]:checked:after {
        border-radius: 0;
        opacity: 1;
    }

    input[type="radio"]:checked {
        background: url("../../../assets/images/radio_s@2x.png") no-repeat;
        background-size: 100% 100%;
    }
</style>
