<template>
    <div class="index-container">
        <BreadcrumbNav :page-name="$t('myPublishedProjects.projectDetails')"/>
        <div class="main">
            <div class="text-group2">
                <h1>{{ projectMsg.project_name }}</h1>
                <div class="text-group2-content">
                    <div class="publish-date fl" style="width: 15%">{{ projectMsg.publish_time | dateYMDHMSFormat }}
                        {{$t("publish.button")}}
                    </div>
                    <div class="brief-intro over fl" style="width: 50%">{{ projectMsg.project_intro }}</div>
                </div>
                <div @click="$router.push({path:'/project/details/'+projectMsg.project_id, query:{type:1}})"
                     class="see-detail-btn">
                    {{$t("myProject.detail")}}
                </div>
            </div>
            <div class="people-wrap">
                <div class="people-info fl">
                    <img :src="projectMsg.initiator_avatar" alt="" class="head-icon fl"/>
                    <div class="people-info-text fl">
                        <h1 class="over">{{ projectMsg.initiator }}</h1>
                        <h2 class="over">{{$t("myInterviewDetail.publisher")}}</h2>
                    </div>
                </div>
            </div>
            <!-- 多人沟通 -->
            <div v-if="projectType == 1">
                <MultiPerson :list="projectMsg.memberMultiInvite"/>
            </div>
            <div class="project-detail" v-else>
                <h1 class="over">{{$t("myProject.freeCommunication")}}</h1>
                <div class="people-wrap">
                    <div class="people-info fl">
                        <img :src="projectMsg.memberSoloInvite.avatar" alt="" class="head-icon fl"/>
                        <div class="people-info-text fl">
                            <h1 class="over">{{ projectMsg.memberSoloInvite.name }}</h1>
                            <h2 class="over">
                                {{ projectMsg.memberSoloInvite.company_name }} ·
                                {{ projectMsg.memberSoloInvite.position }}
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <!--预约时间-->
            <AppointmentTime :type="parseInt(projectType)"/>

            <div class="project-detail">
                <!-- 翻译：项目详情-->
                <h1>{{ $t("vipRecommend.projectDetail") }}</h1>
                <div class="rich-text">
                    <p v-html="projectMsg.details"></p>
                </div>
            </div>
            <!-- 底下vip -->
            <div class="six_box">
                <div class="fl six_left">
                    <h2>VIP</h2>
                    <p>{{$t("publish.bottomStr")}}</p>
                </div>
                <div class="fr six_right">Upgrade VIP</div>
            </div>
        </div>
    </div>
</template>

<script>
    import BreadcrumbNav from "@/components/BreadcrumbNav";
    import MorePersonInviteTable from "@/components/my_project/MorePersonInviteTable";
    import {skipToMultiInvite} from "@/api/myProjectApi/publishedApi";
    import MultiPerson from "@/components/my_project/MultiPersonFreeCommunication";
    import AppointmentTime from "@/components/my_project/my_published_projects/AppointmentTime";

    export default {
        name: "Multiperson",
        data() {
            return {
                projectId: "",
                projectMsg: "",
                projectMum: "",
                projectMum2: "",
                projectType: "",
            };
        },
        components: {
            BreadcrumbNav,
            MorePersonInviteTable,
            MultiPerson,
            AppointmentTime,
        },
        props: {},
        created() {

            this.projectId = this.$route.query.id;
            this.projectType = this.$route.query.type;
            if (this.$route.query.num.indexOf(",") > -1) {
                this.projectMum = this.$route.query.num;
                this.projectMum2 = this.$route.query.num.split(",")[0];
            } else {
                this.projectMum = this.$route.query.num;
                this.projectMum2 = this.$route.query.num;
            }
            this.many_communicate();
        },
        methods: {
            //  多人沟通项目获取
            async many_communicate() {
                let formData = {
                    projectId: this.projectId,
                    token: localStorage.getItem("token"),
                    inviteeIds: this.projectMum,
                    interviewId: this.projectMum2,
                    detailType: this.$route.query.pd,
                };
                let res = await skipToMultiInvite(formData);
                if (res.code == 200) {
                    this.projectMsg = res.data;
                } else {
                    this.$message.error(res.msg)
                }
            },
            //
            gopage(url) {
                this.$router.push(url);
            },
        },
    };
</script>

<style scoped>
    .project-detail {
        margin-top: 72px;
    }

    .project-detail > h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
    }

    .project-detail .rich-text {
        margin-top: 42px;
    }

    .six_box {
        width: 1200px;
        height: 150px;
        border-radius: 4px;
        background: url("../../../assets/images/vipbg@2x.png") no-repeat;
        background-size: 100% 100%;
        margin-top: 75px;
        margin-bottom: 30px;
    }

    .six_left {
        margin-left: 112px;
        padding-top: 43px;
    }

    .six_left h2 {
        font-size: 27px;
        font-family: BentonSans;
        font-weight: 900;
        line-height: 32px;
        color: #ffffff;
        margin-bottom: 10px;
    }

    .six_left p {
        font-size: 17px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 20px;
        color: #ffffff;
    }

    .six_right {
        width: 150px;
        height: 49px;
        border: 1px solid #949494;
        border-radius: 25px;
        box-sizing: border-box;
        line-height: 47px;
        text-align: center;
        margin-right: 209px;
        margin-top: 59px;
        font-size: 14px;
        font-family: BentonSans;
        font-weight: 500;
        color: #ffffff;
    }

    .index-container {
        background-color: #ffffff;
    }

    .main {
        width: 1200px;
        padding-top: 14px;
        padding-bottom: 24px;
        margin: 0 auto;
    }

    .text-group2 {
        box-sizing: border-box;
        width: 100%;
        min-height: 120px;
        border-bottom: 1px solid #e5e5e5;
        padding-top: 30px;
        position: relative;
    }

    .see-detail-btn {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 25px;
        width: 124px;
        height: 40px;
        border: 1px solid #707070;
        border-radius: 131px;
        text-align: center;
        line-height: 40px;
    }

    .text-group2-content {
        margin-top: 8px;
    }

    .publish-date {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
    }

    .brief-intro {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
        margin-left: 16px;
    }

    .text-group2 > h1 {
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
    }

    .people-wrap {
        padding: 38px 0;
        overflow: hidden;
        border-bottom: 1px solid #e5e5e5;
    }

    .people-wrap .head-icon {
        width: 67px;
        height: 67px;
        background: rgba(0, 0, 0, 0);
        border-radius: 50%;
    }

    .people-info-text {
        margin-left: 13px;
        min-width: 160px;
        max-width: 300px;
    }

    .people-info-text > h1 {
        font-size: 17px;
        font-family: BentonSans;
        font-weight: 500;
        color: #000000;
        margin-top: 8px;
    }

    .people-info-text > h2 {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #949494;
        margin-top: 13px;
    }

    .people-wrap .btn-group {
        margin-top: 4px;
        margin-left: 136px;
    }

    hr {
        background-color: #e5e5e5;
    }
</style>
